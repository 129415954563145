<template>
  <div>
    <el-card v-cus-loading="loading.main" class="box-card">
      <div slot="header">
        <span>{{ $tc('voucher', 1) }}</span>
        <router-link
          class="card-header-button el-button el-button--primary el-button--mini"
          style="float: right"
          tag="button"
          to="/vouchers/create"
        >
          <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
        </router-link>
      </div>
      <br />
      <el-tabs :key="1" v-model="tab.active">
        <el-tab-pane :label="$tc('voucher')" name="voucher">
          <el-form :inline="false" :model="table.filter">
            <el-row :gutter="12">
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item>
                  <select-year
                    v-model="table.filter.voucherYear"
                    :placeholder="$tc('year')"
                    :clearable="true"
                  ></select-year>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item>
                  <select-month
                    v-model="table.filter.voucherMonth"
                    :clearable="true"
                    :placeholder="$tc('month')"
                  ></select-month>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item>
                  <select-service
                    v-model="table.filter.serviceId"
                    :label-all="true"
                  ></select-service>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item v-if="!table.filter.serviceId">
                  <select-company
                    v-model="table.filter.companyKey"
                    :label-all="true"
                  ></select-company>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item v-if="table.filter.serviceId">
                  <select-company-by-service
                    v-model="table.filter.companyKey"
                    :label-all="true"
                    :service-id="table.filter.serviceId"
                  ></select-company-by-service>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item>
                  <select-voucher-status
                    v-model="table.filter.voucherStatus"
                    :clearable="true"
                    :label-all="true"
                  ></select-voucher-status>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item>
                  <select-user-is-seller
                    v-model="table.filter.vendorUserId"
                    :clearable="true"
                  ></select-user-is-seller>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item :label="' '">
                  <el-button
                    size="mini"
                    type="primary"
                    :loading="table.loading"
                    @click="getData(1, 'voucher')"
                  >
                    {{ $tc('search') }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$tc('payment', 3)" name="voucherPayment">
          <el-form :inline="false" :model="table.filter">
            <el-row :gutter="12">
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item>
                  <select-year
                    v-model="table.filter.voucherYear"
                    :placeholder="$tc('year')"
                    :clearable="true"
                  ></select-year>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item>
                  <select-month
                    v-model="table.filter.voucherMonth"
                    :clearable="true"
                    :placeholder="$tc('month')"
                  ></select-month>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item>
                  <select-service
                    v-model="table.filter.serviceId"
                    :label-all="true"
                  ></select-service>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item v-if="!table.filter.serviceId">
                  <select-company
                    v-model="table.filter.companyKey"
                    :label-all="true"
                  ></select-company>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item v-if="table.filter.serviceId">
                  <select-company-by-service
                    v-model="table.filter.companyKey"
                    :label-all="true"
                    :service-id="table.filter.serviceId"
                  ></select-company-by-service>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5">
                <el-form-item>
                  <select-user-is-seller
                    v-model="table.filter.vendorUserId"
                    :clearable="true"
                  ></select-user-is-seller>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="3">
                <el-form-item :label="' '">
                  <el-button
                    size="mini"
                    type="primary"
                    :loading="table.loading"
                    @click="getData(1, 'payment')"
                  >
                    {{ $tc('search') }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <br />
      <el-table
        v-loading="table.loading"
        :data="table.data"
        style="width: 100%"
        stripe
        border
      >
        <el-table-column :width="150" align="center">
          <template slot-scope="scope">
            <el-tooltip
              v-if="
                roleIsAdministrator || scope.row.vendorUserId == setting.userId
              "
              :content="$tc('update')"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                v-if="
                  ['Open', 'PartialPayment'].includes(scope.row.voucherStatus)
                "
                size="mini"
                circle
                icon="el-icon-edit"
                @click="onUpdate(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              v-if="
                roleIsAdministrator || scope.row.vendorUserId == setting.userId
              "
              :content="`${$tc('edit')} ${$tc('status')}`"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                v-if="
                  ['Open', 'PartialPayment'].includes(scope.row.voucherStatus)
                "
                size="mini"
                circle
                icon="el-icon-collection-tag"
                @click="onChangeStatus(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              v-cus-validate-role="[1, 2]"
              :content="$tc('pay')"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                v-if="
                  ['Open', 'PartialPayment'].includes(scope.row.voucherStatus)
                "
                size="mini"
                circle
                icon="el-icon-money"
                @click="onPayment(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              v-cus-validate-role="[1, 2]"
              :content="$tc('collect')"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                v-if="
                  ['Open', 'PartialPayment'].includes(
                    scope.row.voucherStatus,
                  ) && scope.row.companyServiceIsSubscribedCollect
                "
                size="mini"
                circle
                icon="el-icon-bank-card"
                @click="onCollect(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              :content="$tc('sendEmail')"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                v-if="
                  ['Open', 'PartialPayment'].includes(scope.row.voucherStatus)
                "
                size="mini"
                circle
                icon="el-icon-message"
                @click="onSendEmail(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              :content="$tc('logEmail')"
              class="item"
              effect="dark"
              placement="top-start"
            >
              <el-button
                size="mini"
                circle
                icon="el-icon-s-promotion"
                @click="onViewLogEmail(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$tc('status')" :width="90" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="right">
              <div slot="content">
                <p>
                  <strong>{{ $tc('statusDate') }}</strong
                  >: {{ formatToDate(scope.row.voucherStatusDate) }}
                </p>
                <p>
                  <strong>{{ $tc('statusPayment') }}</strong
                  >:
                  {{
                    $tc((scope.row.voucherPaymentStatus || '').toLowerCase())
                  }}
                </p>
                <p>
                  <strong>{{ $tc('statusReason') }}</strong
                  >:{{ scope.row.voucherStatusReason }} -
                  <em>({{ scope.row.voucherUpdatedBy }})</em>
                </p>
              </div>
              <span
                :class="{
                  success: scope.row.voucherStatus.toLowerCase() === 'paid',
                  warning: scope.row.voucherStatus.toLowerCase() === 'annuled',
                  danger: scope.row.voucherStatus.toLowerCase() === 'error',
                  default: scope.row.voucherStatus.toLowerCase() === 'open',
                }"
                class="tag"
              >
                {{ $tc(toCamelCase(scope.row.voucherStatus), 1) }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :width="60"
          align="center"
          prop="voucherConsecutive"
          label="#"
        ></el-table-column>
        <el-table-column
          :label="$tc('company')"
          :width="400"
          prop="companyName"
        ></el-table-column>
        <el-table-column :label="$tc('invoice')" :width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.invoiceName" class="tag info">
              <a
                :href="
                  'https://app.aliaddo.com/invoices/Tracking?apiKey=26fc9f6572f34d7ba6b0519f12f4232a&email=&id=' +
                  scope.row.invoiceId
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ scope.row.invoiceName }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$tc('date')" :width="100">
          <template slot-scope="scope">
            {{ scope.row.voucherDate | formatToDate }}
          </template>
        </el-table-column>
        <el-table-column type="expand" width="30">
          <template slot-scope="scope">
            <p>
              <strong>{{ $tc('year') }}: </strong>
              {{ scope.row.voucherYear }}
              <strong>{{ $tc('month') }}: </strong>
              {{ scope.row.voucherMonth }}
            </p>
            <p>
              <strong>{{ $tc('due') }}: </strong>
              {{ scope.row.voucherDateDue | formatToDate }}
            </p>
            <p>
              <strong>{{ $tc('statusDate') }}: </strong>
              {{ scope.row.voucherStatusDate | formatToDate }}
            </p>
            <p>
              <strong>{{ $tc('period') }}: </strong>
              {{ scope.row.voucherDateStart | formatToDate }} -
              {{ scope.row.voucherDateEnd | formatToDate }}
            </p>
            <p>
              <strong>{{ $tc('recurrence') }}: </strong>
              {{
                $tc($options.filters.toCamelCase(scope.row.voucherRecurrence))
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$tc('amount')" :width="150">
          <template slot-scope="scope">
            {{
              $options.filters.formatToCurrencyWithIsoCode(
                scope.row.voucherAmountTotal,
              )
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$tc('payment', 2)" :width="100" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="onViewPlaceToPayPayments(scope.row)"
            >
              {{ $tc('placeToPay') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          :label="$tc('statusPayment')"
          :width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="onValidatePayment(scope.row)"
            >
              {{ $tc('view') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column :label="$tc('service')" :width="100" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceName }}
          </template>
        </el-table-column>
        <el-table-column :label="$tc('seller')" align="center">
          <template slot-scope="scope">
            {{ scope.row.userFullName }}
          </template>
        </el-table-column>
      </el-table>
      <br />
      <!-- <!~~ #region Pagination ~~> -->
      <el-pagination
        :current-page.sync="table.query.page"
        :page-size="10"
        :page-sizes="[10, 20, 30, 40, 50]"
        :total="table.query.count"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="getData(table.query.page, table.filter.type)"
        @size-change="getData(table.query.page, table.filter.type)"
      ></el-pagination>
      <!-- <!~~ #endregion ~~> -->
    </el-card>
    <el-dialog
      :loading="loading.viewPayments"
      :visible.sync="dialog.viewPayments.visible"
      width="70%"
    >
      <place-to-pay-payments
        v-if="dialog.viewPayments.visible"
        :voucher-id="model.selected.voucherId"
      ></place-to-pay-payments>
    </el-dialog>
    <!-- <!~~ #region Pagar ~~> -->
    <el-dialog
      :close-on-click-modal="false"
      :title="$tc('taxes', 3)"
      :visible.sync="dialog.payment.visible"
      width="70%"
      append-to-body
    >
      <el-row :gutter="20">
        <el-col :xs="24" :sm="14">
          <el-form
            ref="formPayment"
            v-cus-loading="formVoucher.loading"
            :model="formVoucher.model"
          >
            <el-form-item :label="$tc('paymentMethod')">
              <select-voucher-payment-method
                v-model="formVoucher.model.voucherPaymentMethod"
              >
              </select-voucher-payment-method>
            </el-form-item>
            <el-form-item :label="$tc('reteFuente')">
              <el-input
                v-model="formVoucher.model.voucherReteFuentePercent"
                type="numeric"
                :placeholder="$tc('reteFuente')"
              >
                <template slot="append"> % </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$tc('reteIca')">
              <el-input
                v-model="formVoucher.model.voucherReteIcaPercent"
                type="numeric"
                :placeholder="$tc('reteIca')"
              >
                <template slot="append"> x1000 </template>
              </el-input>
            </el-form-item>
            <el-form-item
              v-if="+formVoucher.model.voucherIvaValue > 0"
              :label="$tc('reteIva')"
            >
              <el-input
                v-model.number="formVoucher.model.voucherReteIvaPercent"
                type="numeric"
                :placeholder="$tc('reteIva')"
              >
                <template slot="append"> % </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="10">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>{{ $tc('total', 2) }}</span>
            </div>
            <table width="100%">
              <col style="width: 40%" />
              <col style="width: 60%" />
              <tfoot>
                <tr>
                  <td>{{ $tc('base', 1) }}</td>
                  <td align="right">
                    {{
                      formVoucher.model.voucherTaxBase
                        | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('reteFuente', 1) }}</td>
                  <td align="right">
                    {{ voucherReteFuenteValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('reteIca', 2) }}</td>
                  <td align="right">
                    {{ voucherReteIcaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr v-if="+formVoucher.model.voucherIvaValue > 0">
                  <td>{{ $tc('reteIva') }}</td>
                  <td align="right">
                    {{ voucherReteIvaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3>{{ $tc('total') }}</h3>
                  </td>
                  <td align="right">
                    <h3>
                      {{ voucherAmountTotal | formatToCurrencyWithIsoCode }}
                    </h3>
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          :loading="formVoucher.loading"
          @click="onPaymentConfirm(formVoucher.model)"
          >{{ $tc('pay') }}</el-button
        >
      </span>
    </el-dialog>
    <!-- <!~~ #endregion ~~> -->
    <!-- <!~~ #region Enviar email ~~> -->
    <el-dialog
      :title="$tc('sendEmail', 3)"
      :visible.sync="dialog.emailNotification.visible"
      width="45%"
      append-to-body
    >
      <el-form
        ref="formSendEmail"
        v-cus-loading="loading.emailNotification"
        :model="formVoucher.model"
        label-width="180px"
      >
        <el-form-item :label="$tc('type')">
          <el-select
            v-model="model.emailNotification.type"
            :placeholder="$tc('type')"
          >
            <el-option label="Nueva orden de venta" value="VoucherNew">
              Nueva orden de venta
            </el-option>
            <el-option
              v-cus-validate-role="[1, 2]"
              label="Orden de venta vencido"
              value="VoucherDue"
            >
              Orden de venta vencido
            </el-option>
            <el-option
              v-cus-validate-role="[1, 2]"
              label="Empresa a modo lectura"
              value="CompanyRead"
            >
              Empresa a modo lectura
            </el-option>
            <el-option
              v-cus-validate-role="[1, 2]"
              label="Empresa suspendida"
              value="CompanySuspended"
            >
              Empresa suspendida
            </el-option>
            <el-option
              v-cus-validate-role="[1, 2]"
              label="Empresa cancelada"
              value="CompanyCanceled"
            >
              Empresa cancelada
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          :loading="loading.emailNotification"
          @click="onSendEmailConfirm(formVoucher.model)"
          >{{ $tc('sendEmail') }}</el-button
        >
      </span>
    </el-dialog>
    <!-- <!~~ #endregion ~~> -->

    <el-dialog
      :title="$tc('logEmail', 1)"
      :visible.sync="dialog.logEmail.visible"
      width="45%"
      append-to-body
    >
      <log-email
        v-if="dialog.logEmail.visible"
        :entity-id="dialog.logEmail.entityId"
      ></log-email>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="$tc('status', 1)"
      :visible.sync="dialog.voucherStatus.visible"
      width="45%"
      append-to-body
    >
      <voucher-status
        v-if="dialog.voucherStatus.visible"
        :model="formVoucher.model"
        @paid="onPayment($event)"
        @updated="
          getData(table.query.page, table.filter.type)
          showDialog()
        "
      ></voucher-status>
    </el-dialog>
  </div>
</template>
<script>
/* nbxr
-------------------------------------------------- */
// import { paginator } from '@/helpers'
import { mapFilters } from '@/filters/map-filters'
import dialog from '@/views/common/dialog'
import form from '@/views/common/form'
import list, { table } from '@/views/common/list'
import { mapGetters } from 'vuex'
import model from './model'

/* End of nbxr
-------------------------------------------------- */
export default {
  components: {
    placeToPayPayments: _ => import('@/views/placeToPay/payments'),
    logEmail: _ => import('@/views/log_email/index'),
    voucherStatus: _ => import('./status'),
  },
  extends: list('vouchers', { updateRoute: 'VouchersUpdate' }),
  data() {
    return {
      dialog: {
        payment: dialog(),
        emailNotification: dialog(),
        viewPayments: dialog(),
        logEmail: {
          ...dialog(),
          entityId: '',
        },
        voucherStatus: {
          ...dialog(),
          model: {},
        },
      },
      loading: {
        collect: false,
        emailNotification: false,
        viewPayments: false,
      },
      model: {
        emailNotification: {
          type: 'VoucherNew',
        },
        selected: {
          voucherId: '',
        },
      },
      formVoucher: form({ model }),
      tab: {
        active: 'voucher',
      },
      table: {
        ...table(),
        ...{
          filter: {
            type: 'voucher',
            companyKey: null,
            serviceId: null,
            voucherStatus: null,
            voucherYear: new Date().getFullYear(),
            voucherMonth: null,
            vendorUserId: '',
          },
        },
      },
    }
  },
  methods: {
    ...mapFilters(['toCamelCase', 'formatToDate']),
    onChangeStatus(voucher) {
      const _this = this
      _this.showDialog('voucherStatus')
      _this.formVoucher.model = { ...voucher }
    },
    onCollect(voucher) {
      const _this = this
      _this
        .$msgbox({
          title: _this.$tc('collectAutomatic'),
          message: _this.$tc('collectAutomaticMessage'),
          showCancelButton: true,
          confirmButtonText: _this.$tc('collect'),
          cancelButtonText: _this.$tc('cancel'),
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              _this.$http
                .post(`payments/placeToPay/${voucher.id}/collect`)
                .then(response => {
                  _this.onResultPayment(response.body)
                  done()
                })
                .finally(_ => {
                  _this.table.loading = false
                  instance.confirmButtonLoading = false
                })
            } else {
              done()
            }
          },
        })
        .then(_ =>
          _this.getData(_this.table.query.page, _this.table.filter.type),
        )
        .finally(_ => (_this.loading.collect = false))
    },
    onSendEmail(voucher) {
      const _this = this
      _this.showDialog('emailNotification')
      _this.formVoucher.model = voucher
    },
    onSendEmailConfirm(voucher) {
      const _this = this
      _this.loading.emailNotification = true
      _this.$http
        .patch(
          `vouchers/${voucher.id}/${_this.model.emailNotification.type}/emailNotification`,
        )
        .then(response => {
          _this.$message({
            message: _this.$tc('emailNotificationSuccess'),
            type: 'success',
          })
        })
        .finally(_ => (_this.loading.emailNotification = false))
    },
    onPayment(voucher) {
      const _this = this
      _this.showDialog('payment')
      _this.formVoucher.model = {
        ...voucher,
        voucherPaymentMethod: 'Consignacion',
      }
    },
    onPaymentConfirm(voucher) {
      const _this = this

      _this.formVoucher.model = {
        ..._this.formVoucher.model,
        voucherReteFuenteValue: _this.voucherReteFuenteValue,
        voucherReteIcaValue: _this.voucherReteIcaValue,
        voucherReteIvaValue: _this.voucherReteIvaValue,
        voucherAmountTotal: _this.voucherAmountTotal,
      }

      _this.formVoucher.loading = true
      _this.$http
        .post(`vouchers/${voucher.id}/payment`, _this.formVoucher.model)
        .then(result => {
          _this.getData(_this.table.query.page, _this.table.filter.type)
          _this.showDialog()
        })
        .finally(_ => (_this.formVoucher.loading = false))
    },
    onResultPayment(result) {
      const _this = this
      if (
        result.payment &&
        result.payment.status &&
        result.payment.status.status === 'REJECTED'
      ) {
        _this.$message({
          message: _this.$tc('paymentRejected'),
          type: 'error',
        })
      } else if (
        result.payment &&
        result.payment.status &&
        result.payment.status.status === 'APPROVED'
      ) {
        _this.$message({
          message: _this.$tc('paymentSuccess'),
          type: 'success',
        })
      } else if (
        result.payment &&
        result.payment.status &&
        result.payment.status.status === 'PENDING'
      ) {
        _this.$message({
          message: _this.$tc('paymentPending'),
          type: 'warning',
        })
      } else if (
        result.payment &&
        result.payment.status &&
        result.payment.status
      ) {
        _this.$message({
          message: _this.$tc('paymentWithStatus', {
            status: result.payment.status.status,
          }),
          type: 'info',
        })
      } else if (
        result.placeToPay &&
        result.placeToPay.placeToPayStatusMessage
      ) {
        _this.$message({
          message: result.placeToPay.placeToPayStatusMessage,
          type: 'info',
        })
      } else {
        _this.$message({
          message: _this.$tc('noHasPayment'),
          type: 'info',
        })
      }
    },
    onTableCommand(command, voucher) {
      if (command) {
        this[command](voucher)
      }
    },
    onValidatePayment(voucher) {
      const _this = this
      _this.table.loading = true
      _this.$http
        .patch(`payments/placeToPay/${voucher.id}/validatePayment`)
        .then(response => {
          _this.onResultPayment(response.body)
          _this.getData(_this.table.query.page, _this.table.filter.type)
        })
        .finally(_ => (_this.table.loading = false))
    },
    onViewPlaceToPayPayments(voucher) {
      const _this = this
      _this.showDialog('viewPayments')
      _this.model.selected.voucherId = voucher.id
    },
    onViewLogEmail(voucher) {
      const _this = this
      _this.showDialog('logEmail')
      _this.dialog.logEmail.entityId = voucher.id
    },
    getData(pageNumber, type) {
      const _this = this

      _this.table.filter.type = type

      let queryStringParams = {}

      if (_this.table.filter.voucherMonth) {
        queryStringParams = {
          ...queryStringParams,
          voucherMonth: _this.table.filter.voucherMonth,
        }
      }

      if (_this.table.filter.vendorUserId) {
        queryStringParams = {
          ...queryStringParams,
          vendorUserId: _this.table.filter.vendorUserId,
        }
      }

      if (_this.table.filter.companyKey) {
        queryStringParams = {
          ...queryStringParams,
          companyKey: _this.table.filter.companyKey,
        }
      }

      if (_this.table.filter.serviceId) {
        queryStringParams = {
          ...queryStringParams,
          serviceId: _this.table.filter.serviceId,
        }
      }

      if (_this.table.filter.voucherStatus) {
        queryStringParams = {
          ...queryStringParams,
          voucherStatus: _this.table.filter.voucherStatus,
        }
      }

      if (_this.table.filter.type === 'payment') {
        queryStringParams = {
          ...queryStringParams,
          voucherStatus: 'paid',
        }
      }

      if (_this.roleIsAdministrator || _this.table.filter.companyKey) {
        queryStringParams = {
          ...queryStringParams,
          onlyVoucherHasVendor: 'false',
        }
      }

      let uri = ''

      if (
        _this.table.filter.type === 'payment' &&
        !_this.table.filter.voucherYear &&
        _this.table.filter.companyKey
      ) {
        uri = `findByCompanyPaymentSearch/${_this.table.filter.companyKey}`
      } else if (
        _this.table.filter.type === 'payment' &&
        _this.table.filter.voucherYear
      ) {
        uri = `findByYearPaymentSearch/${_this.table.filter.voucherYear}`
      } else if (
        !_this.table.filter.voucherYear &&
        _this.table.filter.companyKey
      ) {
        uri = `findByCompanySearch/${_this.table.filter.companyKey}`
      } else if (_this.table.filter.voucherYear) {
        uri = `findByYearSearch/${_this.table.filter.voucherYear}`
      }

      if (+uri.length <= 0) {
        _this.$message({
          message: _this.$tc('youMustSelectYearOrCompany'),
          type: 'warning',
        })
      } else {
        _this.get(uri, { pageNumber, queryStringParams })
      }
    },
    showDialog(dialog) {
      const _this = this
      for (const dialogName in _this.dialog) {
        _this.dialog[dialogName].visible = false
      }
      if (_this.dialog[dialog]) {
        _this.dialog[dialog].visible = true
      }
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
    roleIsSeller: _this => [4].includes(+_this.setting.roleId),
    roleIsSupport: _this => [3].includes(+_this.setting.roleId),
    voucherReteFuenteValue: _this => {
      const value =
        _this.formVoucher.model.voucherTaxBase *
        (_this.formVoucher.model.voucherReteFuentePercent / 100)
      if (+value > 0) {
        return value.toFixed(2)
      }

      return 0
    },
    voucherReteIcaValue: _this => {
      const value =
        _this.formVoucher.model.voucherTaxBase *
        (_this.formVoucher.model.voucherReteIcaPercent / 1000)
      if (+value > 0) {
        return value.toFixed(2)
      }

      return 0
    },
    voucherReteIvaValue: _this => {
      const value =
        _this.formVoucher.model.voucherIvaValue > 0
          ? +(
              _this.formVoucher.model.voucherIvaValue *
              (_this.formVoucher.model.voucherReteIvaPercent / 100)
            )
          : 0
      if (+value > 0) {
        return value.toFixed(2)
      }

      return 0
    },
    voucherAmountTotal: _this =>
      (_this.formVoucher.model.voucherAmountTotal || 0) -
      (_this.voucherReteFuenteValue || 0) -
      (_this.voucherReteIcaValue || 0) -
      (_this.voucherReteIvaValue || 0),
  },
  created() {
    const _this = this
    if (_this.roleIsSeller === 4) {
      _this.table.filter.vendorUserId = _this.setting.userId
    }
    _this.getData(1, 'voucher')
  },
}
</script>
